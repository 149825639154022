import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import log from 'loglevel';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ClassSelector } from './ClassIcons';
import { MobileFriendlyDialog } from './MobileFriendlyDialog';
import { MobileFriendlyDialogTitle } from './MobileFriendlyDialogTitle';

function Home(): JSX.Element {
  const navigate = useNavigate();

  const [rejoinCoide, setRejoinCode] = useState('');
  const [showHostDialog, setShowHostDialog] = useState(false);
  const [showJoinDialog, setShowJoinDialog] = useState(false);

  const joinRoom = useCallback(
    (code: string) => {
      const sanitized = code.trim();

      navigate(`/play/${sanitized}`);
    },
    [navigate],
  );

  const handleHostDialogClose = useCallback(() => {
    setShowHostDialog(false);
  }, []);

  const handleJoinDialogClose = useCallback(() => {
    setShowJoinDialog(false);
  }, []);

  const openJoinDialog = useCallback(() => {
    setShowJoinDialog(true);
  }, []);

  const openHostDialog = useCallback(() => {
    setShowHostDialog(true);
  }, []);

  const rejoinRoom = useCallback(() => {
    joinRoom(rejoinCoide);
  }, [joinRoom, rejoinCoide]);

  useEffect(() => {
    async function checkExistingRoom(): Promise<void> {
      try {
        const response = await axios.get('/previousRoom');
        const rejoinCode = response.data as string;

        setRejoinCode(rejoinCode);
      } catch (error) {
        log.error(error);
      }
    }

    checkExistingRoom();
  }, []);

  return (
    <Stack gap="24px" paddingBottom="24px" paddingTop="24px">
      <Typography textAlign="center" variant="h3">
        ddduel
      </Typography>
      <Stack direction="row" gap="16px" justifyContent="center">
        <Button variant="outlined" onClick={openHostDialog}>
          Host Room
        </Button>
        <Button variant="outlined" onClick={openJoinDialog}>
          Join Room
        </Button>
        {rejoinCoide !== '' && (
          <Button variant="outlined" onClick={rejoinRoom}>
            Rejoin Room
          </Button>
        )}
      </Stack>

      <HostGameDialog joinRoom={joinRoom} open={showHostDialog} onClose={handleHostDialogClose} />

      <JoinRoomDialog open={showJoinDialog} onClose={handleJoinDialogClose} />
    </Stack>
  );
}

type HostGameDialogProps = {
  joinRoom: (code: string) => unknown;
  open: boolean;
  onClose: () => unknown;
};

function HostGameDialog({ joinRoom, onClose, open }: HostGameDialogProps) {
  const [className, setClassName] = useState('');
  const [username, setUsername] = useState('');

  const handleUsernameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  }, []);

  const hostRoom = useCallback(async () => {
    log.debug('hosting game');

    try {
      const response = await axios.post('/host', {
        username,
        className,
      });
      const code = `${response.data}`;

      joinRoom(code);
    } catch (error) {
      log.error(error);
      // alert('Error hosting game');
    }
  }, [className, joinRoom, username]);

  const handleFormSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();

      hostRoom();
    },
    [hostRoom],
  );

  return (
    <MobileFriendlyDialog open={open} onClose={onClose}>
      <form onSubmit={handleFormSubmit}>
        <MobileFriendlyDialogTitle onClose={onClose}>Host Game</MobileFriendlyDialogTitle>

        <DialogContent>
          <Stack gap="16px" paddingTop="6px">
            <TextField autoFocus fullWidth label="Username" required value={username} onChange={handleUsernameChange} />
            <ClassSelector onChange={setClassName} />
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button disabled={!className} type="submit" variant="outlined">
            Host
          </Button>
        </DialogActions>
      </form>
    </MobileFriendlyDialog>
  );
}

type JoinRoomDialogProps = {
  open: boolean;
  onClose: () => unknown;
};

function JoinRoomDialog({ onClose, open }: JoinRoomDialogProps) {
  const [joinCode, setJoinCode] = useState('');
  const navigate = useNavigate();

  const handleJoinCodeChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setJoinCode(event.target.value);
  }, []);

  const handleFormSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();

      navigate(`/joinRoom/${joinCode}`);
    },
    [joinCode, navigate],
  );

  return (
    <MobileFriendlyDialog open={open} onClose={onClose}>
      <form onSubmit={handleFormSubmit}>
        <MobileFriendlyDialogTitle onClose={onClose}>Join Game</MobileFriendlyDialogTitle>

        <DialogContent>
          <Box paddingTop="6px">
            <TextField
              autoFocus
              fullWidth
              label="Room Code"
              required
              value={joinCode}
              onChange={handleJoinCodeChange}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button type="submit" variant="outlined">
            Join
          </Button>
        </DialogActions>
      </form>
    </MobileFriendlyDialog>
  );
}

export default Home;
