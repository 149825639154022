import { useMemo } from 'react';

import { useRoomState } from './useRoomState';

export function useReady(playerUuid?: string) {
  const { state } = useRoomState();
  const { readyPlayers, uuid } = state;

  const targetUuid = playerUuid ?? uuid;

  return useMemo(() => readyPlayers.has(targetUuid), [readyPlayers, targetUuid]);
}
