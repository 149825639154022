import type { RoomClientEventsMap } from '@common/events/RoomClientEvents';
import type { RoomServerEventsMap } from '@common/events/RoomServerEvents';
import type { PlayerInitiatives } from '@common/misc';
import type PlayerId from '@common/PlayerId';
import { assertExhaustive } from '@common/utils/assertExhaustive';
import { sortPlayerInitiatives } from '@common/utils/sortPlayerInitiatives';
import { stringifyCyclic } from '@src/utils/json';
import { serverLog } from '@src/utils/serverLog';
import { omit } from 'lodash';
import type { Socket } from 'socket.io-client';

import type { RoomAction } from './actions';

type RoomState = {
  allReadyData: PlayerInitiatives[];
  allPlayers: Record<string, PlayerId>;
  exhaustedPlayers: Set<string>;
  host: string;
  initiatives: [string, string];
  missingPlayers: Set<string>;
  ready: boolean;
  showPlayerDialog: string;
  showRenameDialog: boolean;
  showRoundResults: null | 'current' | 'last';
  socket: Socket<RoomServerEventsMap, RoomClientEventsMap> | null;
  roundNumber: number | null;
  readyPlayers: Set<string>;
  username: string;
  uuid: string;
};

function getInitialRoomState(): RoomState {
  return {
    allReadyData: [],
    allPlayers: {},
    exhaustedPlayers: new Set(),
    host: '',
    initiatives: ['', ''],
    missingPlayers: new Set(),
    ready: false,
    showRoundResults: null,
    showPlayerDialog: '',
    showRenameDialog: false,
    socket: null,
    roundNumber: null,
    readyPlayers: new Set(),
    username: '',
    uuid: '',
  };
}

function roomReducer(state: RoomState, action: RoomAction): RoomState {
  switch (action.type) {
    case 'all_ready': {
      return {
        ...state,
        allReadyData: action.playerInitiatives,
      };
    }

    case 'all_players_add': {
      return {
        ...state,
        allPlayers: {
          ...state.allPlayers,
          [action.player.uuid]: action.player,
        },
      };
    }

    case 'all_players_remove': {
      return {
        ...state,
        allPlayers: omit(state.allPlayers, action.uuid),
      };
    }

    case 'exhausted_players_add': {
      return {
        ...state,
        exhaustedPlayers: new Set(...state.exhaustedPlayers, action.uuid),
      };
    }

    case 'exhausted_players_remove': {
      return {
        ...state,
        exhaustedPlayers: new Set([...state.exhaustedPlayers].filter((uuid) => uuid !== action.uuid)),
      };
    }

    case 'host': {
      return {
        ...state,
        host: action.uuid,
      };
    }

    case 'initiative_1': {
      return {
        ...state,
        initiatives: [action.initiative, state.initiatives[1]],
      };
    }

    case 'initiative_2': {
      return {
        ...state,
        initiatives: [state.initiatives[0], action.initiative],
      };
    }

    case 'long_rest': {
      return {
        ...state,
        initiatives: ['99', '99'],
        ready: true,
      };
    }

    case 'missing_players_add': {
      return {
        ...state,
        missingPlayers: new Set([...state.missingPlayers, action.uuid]),
      };
    }

    case 'missing_players_remove': {
      return {
        ...state,
        missingPlayers: new Set([...state.missingPlayers].filter((uuid) => uuid !== action.uuid)),
      };
    }

    case 'ready': {
      return {
        ...state,
        ready: action.ready,
      };
    }

    case 'ready_players_add': {
      return {
        ...state,
        readyPlayers: new Set([...state.readyPlayers, action.uuid]),
      };
    }

    case 'ready_players_remove': {
      return {
        ...state,
        readyPlayers: new Set([...state.readyPlayers].filter((uuid) => uuid !== action.uuid)),
      };
    }

    case 'ready_players_reset': {
      return {
        ...state,
        readyPlayers: new Set<string>(),
      };
    }

    case 'reset_initiatives': {
      const initiatives = action.initiatives ?? getInitialRoomState().initiatives;
      return {
        ...state,
        initiatives,
        ready: false,
      };
    }

    case 'reset_state': {
      return {
        ...getInitialRoomState(),
      };
    }

    case 'resync': {
      const { allPlayerData, lastRound, ...syncState } = action.state;

      const allPlayers = Object.keys(allPlayerData).reduce<Record<string, PlayerId>>((agg, uuid) => {
        const { className, username } = allPlayerData[uuid];
        return {
          ...agg,
          [uuid]: {
            className,
            uuid,
            username,
          },
        };
      }, {});

      const allReadyData = sortPlayerInitiatives(allPlayerData);

      const { exhaustedPlayers, missingPlayers, readyPlayers } = Object.values(allPlayerData).reduce(
        (agg, { exhausted, missing, ready, uuid }) => {
          if (exhausted) {
            agg.exhaustedPlayers.add(uuid);
          }

          if (missing) {
            agg.missingPlayers.add(uuid);
          }

          if (ready) {
            agg.readyPlayers.add(uuid);
          }

          return agg;
        },
        {
          exhaustedPlayers: new Set<string>(),
          missingPlayers: new Set<string>(),
          readyPlayers: new Set<string>(),
        },
      );

      const initiatives = (allPlayerData?.[syncState.uuid]?.initiatives.map((initiative) =>
        initiative === -1 ? '' : `${initiative}`,
      ) as [string, string]) ?? ['', ''];

      const newState: RoomState = {
        ...state,
        ...syncState,
        allPlayers,
        allReadyData,
        exhaustedPlayers,
        initiatives,
        missingPlayers,
        readyPlayers,
      };

      if (lastRound !== undefined) {
        newState.allReadyData = lastRound;
      }

      serverLog(
        `Received resync state: ${stringifyCyclic(action.state)}\n\nHad state: ${stringifyCyclic(
          omit(state, ['socket']),
        )}\n\nResolved state: ${stringifyCyclic(omit(newState, ['socket']))}`,
      );

      return newState;
    }

    case 'round_number': {
      return {
        ...state,
        roundNumber: action.roundNumber,
      };
    }

    case 'show_player_dialog': {
      return {
        ...state,
        showPlayerDialog: action.uuid,
      };
    }

    case 'socket': {
      return {
        ...state,
        socket: action.socket,
      };
    }

    case 'username_change': {
      const { username, uuid } = action;
      return {
        ...state,
        allPlayers: {
          ...state.allPlayers,
          [uuid]: {
            ...state.allPlayers[uuid],
            username,
          },
        },
      };
    }

    case 'uuid': {
      return {
        ...state,
        uuid: action.uuid,
      };
    }

    default: {
      return assertExhaustive(action);
    }
  }
}

export { getInitialRoomState, roomReducer, RoomState };
