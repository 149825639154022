export function stringifyCyclic(any: unknown) {
  const seen = new Set<unknown>();

  return JSON.stringify(any, (_, value) => {
    if (value !== null && typeof value === 'object') {
      if (seen.has(value)) {
        return '<CYCLIC_VALUE>';
      }

      seen.add(value);
    }

    return value;
  });
}
