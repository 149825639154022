import { Button, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { MobileFriendlyDialog } from '@src/Components/MobileFriendlyDialog';
import { MobileFriendlyDialogTitle } from '@src/Components/MobileFriendlyDialogTitle';
import type { ComponentProps } from 'react';
import { createContext, useCallback, useMemo, useState } from 'react';

type PromptConfirmDialog = (options: Partial<DialogOptions>) => Promise<boolean>;

type DialogOptions = {
  title: string;
  message: string;
  confirmText: string;
  confirmColor: ComponentProps<typeof Button>['color'];
  cancelText: string;
  cancelColor: ComponentProps<typeof Button>['color'];
};

type DialogPromiseCallbacks = {
  confirm: () => void;
  deny: () => void;
};

type DialogParameters = {
  options: Partial<DialogOptions>;
  callbacks: DialogPromiseCallbacks;
};

export const ConfirmDialogContext = createContext<{ promptConfirmDialog: PromptConfirmDialog } | null>(null);

type ConfirmDialogProviderProps = { children: React.ReactNode };

export function ConfirmDialogProvider({ children }: ConfirmDialogProviderProps) {
  const [dialogParameters, setDialogParameters] = useState<DialogParameters>();

  const handleClose = useCallback(
    (confirmed: boolean) => {
      setDialogParameters(undefined);
      if (confirmed) {
        dialogParameters?.callbacks.confirm();
      } else {
        dialogParameters?.callbacks.deny();
      }
    },
    [dialogParameters?.callbacks],
  );

  const promptConfirmDialog: PromptConfirmDialog = useCallback(
    async (options) =>
      new Promise((resolve) => {
        setDialogParameters({
          callbacks: {
            confirm: () => resolve(true),
            deny: () => resolve(false),
          },
          options,
        });
      }),
    [],
  );

  const value = useMemo(() => ({ promptConfirmDialog }), [promptConfirmDialog]);

  return (
    <ConfirmDialogContext.Provider value={value}>
      {children}

      {dialogParameters && <ConfirmDialog options={dialogParameters.options} onClose={handleClose} />}
    </ConfirmDialogContext.Provider>
  );
}

type ConfirmDialogProps = {
  onClose: (confirmed: boolean) => unknown;
  options: Partial<DialogOptions>;
};

function ConfirmDialog({ onClose, options }: ConfirmDialogProps) {
  const handleCancel = useCallback(() => onClose(false), [onClose]);
  const handleConfirm = useCallback(() => onClose(true), [onClose]);

  return (
    <MobileFriendlyDialog open onClose={handleCancel}>
      <MobileFriendlyDialogTitle onClose={handleCancel}>{options.title ?? 'Are you sure?'}</MobileFriendlyDialogTitle>
      {options.message && (
        <DialogContent>
          <DialogContentText>{options.message}</DialogContentText>
        </DialogContent>
      )}
      {!options.message && <DialogContent />}
      <DialogActions>
        <Button color={options.cancelColor ?? 'error'} variant="outlined" onClick={handleCancel}>
          {options.cancelText ?? 'Cancel'}
        </Button>
        <Button color={options.confirmColor ?? 'primary'} variant="outlined" onClick={handleConfirm}>
          {options.confirmText ?? 'Confirm'}
        </Button>
      </DialogActions>
    </MobileFriendlyDialog>
  );
}
