import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

function RoomNotFound() {
  const navigate = useNavigate();

  const { roomCode } = useParams();

  const goHome = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <Container>
      <Stack alignItems="center" gap="16px" paddingBottom="24px" paddingTop="24px">
        <Typography textAlign="center" variant="h3">
          ddduel
        </Typography>

        <Typography textAlign="center" variant="h5">
          Room{' '}
          <Typography display="inline" fontFamily="'Roboto Mono', monospace">
            {roomCode}
          </Typography>{' '}
          not found
        </Typography>

        <Box width="min-content">
          <Button variant="outlined" onClick={goHome}>
            Home
          </Button>
        </Box>
      </Stack>
    </Container>
  );
}

export default RoomNotFound;
