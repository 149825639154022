import { Stack } from '@mui/material';
import { AppThemeProvider } from '@src/Providers/AppThemeProvider';
import { ConfirmDialogProvider } from '@src/Providers/ConfirmDialogProvider';
import { RoomStateProvider } from '@src/Providers/RoomStateProvider';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { RouterProvider } from 'react-router';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';

import { Footer } from './Footer';
import Home from './Home';
import JoinRoom from './JoinRoom';
import NotFound from './NotFound';
import Room from './Room';
import RoomNotFound from './RoomNotFound';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<Home />} path="/" />
      <Route element={<JoinRoom />} path="/joinRoom/:roomCode" />
      <Route element={<Room />} path="/play/:roomCode" />
      <Route element={<RoomNotFound />} path="/roomNotFound/:roomCode" />

      <Route element={<NotFound />} path="*" />
      </>
  ),
);

function App(): JSX.Element {
  return (
    <React.StrictMode>
      <AppThemeProvider>
        <SnackbarProvider>
          <ConfirmDialogProvider>
            <RoomStateProvider>
              <Stack
                justifyContent="space-between"
                sx={{
                  minHeight: '100vh',
                }}
              >
                <RouterProvider router={router} />
                <Footer />
              </Stack>
            </RoomStateProvider>
          </ConfirmDialogProvider>
        </SnackbarProvider>
      </AppThemeProvider>
    </React.StrictMode>
  );
}

export default App;
