import { ColorModeContext } from '@src/Providers/AppThemeProvider';
import { useContext } from 'react';

export function useColorMode() {
  const colorMode = useContext(ColorModeContext);
  if (!colorMode) {
    throw new Error('useColorMode used outside of AppThemeProvider.');
  }

  return colorMode;
}
