import type { GloomhavenClassName } from '@common/misc';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import log from 'loglevel';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { ClassSelector } from './ClassIcons';

function JoinRoom(): JSX.Element {
  const navigate = useNavigate();
  const { roomCode } = useParams();

  const [username, setUsername] = useState('');
  const [className, setClassName] = useState<GloomhavenClassName | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  const onFormSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();

      try {
        await axios.post(`/joinRoom/${roomCode}`, {
          className,
          username,
        });

        navigate(`/play/${roomCode}`);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 400) {
            enqueueSnackbar(`Failed to join room - Username ${username} not available.`, {
              variant: 'error',
            });

            return;
          }

          if (error.response?.status === 404) {
            navigate(`/roomNotFound/${roomCode}`);
            return;
          }

          log.error('Error joining room');
        }

        log.error(error);
      }
    },
    [className, enqueueSnackbar, navigate, roomCode, username],
  );

  const handleUsernameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  }, []);

  const buttonDisabled = useMemo(() => !className || !username.trim(), [className, username]);

  return (
    <form onSubmit={onFormSubmit}>
      <Container>
        <Stack alignItems="center" gap="16px" paddingBottom="24px" paddingTop="24px">
          <Typography textAlign="center" variant="h3">
            ddduel
          </Typography>

          <Typography textAlign="center" variant="h5">
            Join Room:{' '}
            <Typography display="inline" fontFamily="'Roboto Mono', monospace">
              {roomCode}
            </Typography>
          </Typography>

          <TextField autoFocus label="Username" required value={username} onChange={handleUsernameChange} />

          <ClassSelector onChange={setClassName} />

          <Box width="min-content">
            <Button disabled={buttonDisabled} type="submit" variant="outlined">
              Join
            </Button>
          </Box>
        </Stack>
      </Container>
    </form>
  );
}

export default JoinRoom;
