import type { License } from '@common/misc';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useColorMode } from '@src/hooks/useColorMode';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';

import { MobileFriendlyDialog } from './MobileFriendlyDialog';
import { MobileFriendlyDialogTitle } from './MobileFriendlyDialogTitle';

export function Footer() {
  const { enqueueSnackbar } = useSnackbar();
  const { mode, toggleMode } = useColorMode();

  const [loadingLicenses, setLoadingLicenses] = useState(false);
  const [licenses, setLicenses] = useState<License[]>([]);
  const [showLicenses, setShowLicenses] = useState(false);

  const closeLicenses = useCallback(() => setShowLicenses(false), [setShowLicenses]);
  const openLicenses = useCallback(async () => {
    try {
      if (licenses.length === 0) {
        setLoadingLicenses(true);

        const response = await axios.get('/licenses');
        setLicenses(response.data);
      }
    } catch (err) {
      enqueueSnackbar('Error loading licenses', { variant: 'error' });
      return;
    } finally {
      setLoadingLicenses(false);
    }

    setShowLicenses(true);
  }, [enqueueSnackbar, licenses]);

  return (
    <Stack direction="row" justifyContent="center" padding="0px 16px 16px 16px">
      <Button size="small" variant="outlined" onClick={toggleMode}>
        {mode === 'dark' ? 'Light' : 'Dark'} mode
      </Button>

      <Box position="absolute" right="16px">
        <Button size="small" onClick={openLicenses}>
          <Stack
            alignItems="center"
            height="100%"
            justifyContent="center"
            position="absolute"
            visibility={loadingLicenses ? 'visible' : 'hidden'}
            width="100%"
          >
            <CircularProgress size={20} />
          </Stack>
          <Box visibility={loadingLicenses ? 'hidden' : 'visible'}>Licenses</Box>
        </Button>
      </Box>

      <LicensesDialog licenses={licenses} open={showLicenses} onClose={closeLicenses} />
    </Stack>
  );
}

type LicensesDialogProps = {
  licenses: License[];
  onClose: () => unknown;
  open: boolean;
};

function LicensesDialog({ licenses, onClose, open }: LicensesDialogProps) {
  return (
    <MobileFriendlyDialog open={open} onClose={onClose}>
      <MobileFriendlyDialogTitle onClose={onClose}>Licenses</MobileFriendlyDialogTitle>
      <DialogContent>
        <Stack gap="16px">
          {licenses.map(({ content, dependencies }) => (
            <Card key={content}>
              <CardContent>
                <Stack gap="8px">
                  <Typography>{content}</Typography>
                  {dependencies && (
                    <>
                      <Typography>Depends on npm packages:</Typography>
                      {dependencies.map((dependency) => (
                        <Card key={dependency} elevation={2}>
                          <CardContent>
                            <Typography>{dependency}</Typography>
                          </CardContent>
                        </Card>
                      ))}
                    </>
                  )}
                </Stack>
              </CardContent>
            </Card>
          ))}
        </Stack>
      </DialogContent>
    </MobileFriendlyDialog>
  );
}
