import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useRoomCode } from '@src/hooks/useRoomCode';
import QRCode from 'qrcode';

import useAsyncMemo from '../hooks/useAsyncMemo';
import { MobileFriendlyDialog } from './MobileFriendlyDialog';
import { MobileFriendlyDialogTitle } from './MobileFriendlyDialogTitle';

type RoomCodeDialogProps = {
  onClose: () => void;
  show: boolean;
};

function RoomCodeDialog({ onClose, show }: RoomCodeDialogProps) {
  const code = useRoomCode();

  const qrCodeDataUrl = useAsyncMemo(
    () =>
      QRCode.toDataURL(`${window.location.origin}/play/${code}`, {
        width: 900,
      }),
    '',
    [code],
  );

  return (
    <MobileFriendlyDialog open={show} onClose={onClose}>
      <MobileFriendlyDialogTitle onClose={onClose} />
      <DialogContent>
        <Stack gap="16px" justifyContent="center" minHeight="100%">
          <img alt="Room QR code" src={qrCodeDataUrl} width="100%" />
          <Typography fontFamily={"'Roboto Mono', monospace"} textAlign="center" variant="h5">
            {code}
          </Typography>
        </Stack>
      </DialogContent>
    </MobileFriendlyDialog>
  );
}

export default RoomCodeDialog;
