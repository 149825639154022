import { RoomStateContext } from '@src/Providers/RoomStateProvider';
import { useContext } from 'react';

export function useRoomState() {
  const roomState = useContext(RoomStateContext);

  if (roomState === null) {
    throw new Error('useRoomState called without RoomStateProvider');
  }

  return roomState;
}
