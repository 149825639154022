import { log } from 'loglevel';

export async function serverLog(message: string) {
  log('serverLog', message);
  return fetch('/log', {
    method: 'POST',
    body: message,
    headers: {
      'Content-type': 'text/plain',
    },
  });
}
