import { faSkull } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useColorMode } from '@src/hooks/useColorMode';
import { useExhausted } from '@src/hooks/useExhausted';
import { useReady } from '@src/hooks/useReady';
import { styled } from 'linaria/lib/react';
import { memo, useMemo } from 'react';

import readyImage from '../assets/ready.png';
import unreadyImage from '../assets/unready.png';

type ReadyIconProps = {
  fontSize?: string;
  playerUuid: string;
};

const ReadyIcon = memo(({ fontSize = '1em', playerUuid }: ReadyIconProps) => {
  const exhausted = useExhausted(playerUuid);
  const ready = useReady(playerUuid);
  const { mode } = useColorMode();

  const src = useMemo(() => (ready ? readyImage : unreadyImage), [ready]);

  if (exhausted) {
    return <FontAwesomeIcon icon={faSkull} inverse={mode === 'dark'} />;
  }

  return <FontSizeImg alt="" fontSize={fontSize} inverse={mode === 'dark'} src={src} />;
});

const FontSizeImg = styled.img<{ fontSize: string; inverse: boolean }>`
  height: ${({ fontSize }) => fontSize};
  filter: invert(${({ inverse }) => (inverse ? '1' : '0')});
`;

export default ReadyIcon;
