import type { DialogProps } from '@mui/material/Dialog';
import Dialog from '@mui/material/Dialog';
import { useIsMobile } from '@src/hooks/useIsMobile';

type MobileFriendlyDialogProps = Omit<DialogProps, 'fullscreen'> & Required<Pick<DialogProps, 'onClose'>>;

export function MobileFriendlyDialog({ children, ...otherProps }: MobileFriendlyDialogProps) {
  const isMobile = useIsMobile();
  return (
    <Dialog fullScreen={isMobile} {...otherProps}>
      {children}
    </Dialog>
  );
}
