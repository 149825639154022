import { useMemo } from 'react';

import { useRoomState } from './useRoomState';

export function useExhausted(playerUuid?: string) {
  const { state } = useRoomState();
  const { exhaustedPlayers, uuid } = state;

  const targetUuid = playerUuid ?? uuid;

  return useMemo(() => exhaustedPlayers.has(targetUuid), [exhaustedPlayers, targetUuid]);
}
