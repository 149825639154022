/* eslint-disable @typescript-eslint/naming-convention */
import type { PlayerInitiatives } from '@common/misc';
import type PlayerId from '@common/PlayerId';
import { Typography } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useRoomState } from '@src/hooks/useRoomState';
import { memo, useMemo } from 'react';

import type { RoomState } from '../Reducers/RoomReducer';
import { ClassIcon } from './ClassIcons';
import DdduelGif from './DdduelGif';
import { MobileFriendlyDialog } from './MobileFriendlyDialog';
import { MobileFriendlyDialogTitle } from './MobileFriendlyDialogTitle';

type RoundResultDialogProps = {
  onClose: () => void;
  show: RoomState['showRoundResults'];
};

function RoundResultDialog(props: RoundResultDialogProps) {
  const { onClose, show } = props;
  const { state } = useRoomState();
  const { allPlayers, allReadyData, roundNumber } = state;

  return (
    <MobileFriendlyDialog disableRestoreFocus open={show !== null} onClose={onClose}>
      <MobileFriendlyDialogTitle onClose={onClose} />
      <DialogContent>
        <Stack gap="16px" justifyContent="center" minHeight="100%">
          <DdduelGif show={show} />
          <Typography textAlign="center" variant="h5">
            Round {(roundNumber ?? 2) - 1}
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell component="th">Player</TableCell>
                  <TableCell colSpan={2} component="th">
                    Initiatives
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allReadyData.map((playerInitiatives) => (
                  <PlayerRow key={playerInitiatives[0]} allPlayers={allPlayers} playerData={playerInitiatives} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </DialogContent>
    </MobileFriendlyDialog>
  );
}

type PlayerRowProps = {
  allPlayers: Record<string, PlayerId>;
  playerData: PlayerInitiatives;
};

function _PlayerRow({ allPlayers, playerData }: PlayerRowProps) {
  const [uuid, initiative1, initiative2] = playerData;

  const formattedInitiative1 = useMemo(() => `${initiative1}`.padStart(2, '0'), [initiative1]);
  const formattedInitiative2 = useMemo(() => `${initiative2}`.padStart(2, '0'), [initiative2]);
  const { className, username } = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { className, username } = allPlayers[uuid];
    return {
      className,
      username,
    };
  }, [allPlayers, uuid]);

  const wordBreakStyle = useMemo(() => ({ wordBreak: 'break-all' } as const), []);

  return (
    <TableRow>
      <TableCell>
        <Stack alignItems="center" direction="row" gap="4px" sx={wordBreakStyle}>
          <ClassIcon name={className} size={16} /> {username}
        </Stack>
      </TableCell>
      <TableCell>{formattedInitiative1}</TableCell>
      <TableCell>{formattedInitiative2}</TableCell>
    </TableRow>
  );
}

const PlayerRow = memo(_PlayerRow);

export default RoundResultDialog;
