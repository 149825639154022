import type PlayerId from '@common/PlayerId';
import { DialogContent } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import { memo, useCallback, useState } from 'react';

import { MobileFriendlyDialog } from './MobileFriendlyDialog';
import { MobileFriendlyDialogTitle } from './MobileFriendlyDialogTitle';

type OtherPlayerDialogProps = {
  playerId: PlayerId;
  onClose(): void;
  onKickPlayer(uuid: string): void;
};

function OtherPlayerDialog({ onClose, onKickPlayer, playerId }: OtherPlayerDialogProps) {
  const handleKickPlayer = useCallback(() => onKickPlayer(playerId.uuid), [onKickPlayer, playerId.uuid]);
  const handlePromotePlayer = useCallback(() => setShowPromotePlayer(true), []);
  const handlePromotePlayerConfirm = useCallback(async () => {
    setShowPromotePlayer(false);
    onClose();

    await axios.post('/promotePlayer', {
      uuid: playerId.uuid,
    });
  }, [onClose, playerId.uuid]);

  const handlePromotePlayerClose = useCallback(() => setShowPromotePlayer(false), []);

  const [showPromotePlayer, setShowPromotePlayer] = useState(false);

  return (
    <MobileFriendlyDialog open={playerId.uuid !== '' && !showPromotePlayer} onClose={onClose}>
      <MobileFriendlyDialogTitle onClose={onClose}>Take action on {playerId.username}</MobileFriendlyDialogTitle>
      <DialogContent />
      <DialogActions>
        <Stack direction="row" gap="16px">
          <Button color="error" variant="outlined" onClick={handleKickPlayer}>
            Kick Player
          </Button>
          <Button color="warning" variant="outlined" onClick={handlePromotePlayer}>
            Make room leader
          </Button>
        </Stack>
      </DialogActions>

      <PromotePlayerDialog
        open={showPromotePlayer}
        username={playerId.username}
        onClose={handlePromotePlayerClose}
        onConfirm={handlePromotePlayerConfirm}
      />
    </MobileFriendlyDialog>
  );
}

type PromotePlayerDialogProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  username: string;
};

function PromotePlayerDialog({ onClose, onConfirm, open, username }: PromotePlayerDialogProps) {
  return (
    <MobileFriendlyDialog open={open} onClose={onClose}>
      <MobileFriendlyDialogTitle onClose={onClose}>Make {username} room leader?</MobileFriendlyDialogTitle>
      <DialogContent />
      ``
      <DialogActions>
        <Stack direction="row" gap="16px" justifyContent="space-between" width="100%">
          <Button color="warning" variant="outlined" onClick={onConfirm}>
            Yes
          </Button>
          <Button color="secondary" variant="outlined" onClick={onClose}>
            Back
          </Button>
        </Stack>
      </DialogActions>
    </MobileFriendlyDialog>
  );
}

export default memo(OtherPlayerDialog);
