import SwapVertIcon from '@mui/icons-material/SwapVert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useRoomState } from '@src/hooks/useRoomState';
import React, { useCallback, useMemo, useState } from 'react';

/**
 * Used to prevent a consistently disadvantageous race condition between
 * initiative onChange, setState, and initiative blur.
 */
let ignoreNextBlur = false;

const validateInitiativeInput = (input: string) => {
  if (input.length === 0) {
    return '';
  }

  const match = input.match(/^(0|0[1-9]?|[1-9][0-9]?)$/);

  if (match === null) {
    return null;
  }

  return match[0];
};

type InitiativesRowProps = {
  firstInputRef: React.RefObject<HTMLInputElement>;
  secondInputRef: React.RefObject<HTMLInputElement>;
};

export function InitiativesRow({ firstInputRef, secondInputRef }: InitiativesRowProps) {
  const { dispatch, state } = useRoomState();
  const {
    initiatives: [initiative1, initiative2],
    ready,
  } = state;

  const longResting = useMemo(
    () => ready && initiative1 === '99' && initiative2 === '99',
    [initiative1, initiative2, ready],
  );

  const [pressingInitiative, setPressingInitiative] = useState(false);

  const hideInitiatives = useMemo(
    () => ready && !longResting && !pressingInitiative,
    [ready, longResting, pressingInitiative],
  );

  const handleInitiativeMouseDown = useCallback(() => setPressingInitiative(true), []);
  const handleInitiativeMouseLeave = useCallback(() => setPressingInitiative(false), []);
  const handleInitiativeMouseUp = useCallback(() => setPressingInitiative(false), []);
  const handleInitiativeTouchEnd = useCallback(() => setPressingInitiative(false), []);
  const handleInitiativeTouchStart = useCallback(() => setPressingInitiative(true), []);
  const handleSwapInitiatives = useCallback(
    () =>
      dispatch({
        type: 'reset_initiatives',
        initiatives: [initiative2, initiative1],
      }),
    [dispatch, initiative1, initiative2],
  );

  const handleInitiative1Blur = useCallback(() => {
    if (ignoreNextBlur) {
      ignoreNextBlur = false;
      return;
    }

    if (initiative1.length === 1 && initiative1 !== '0') {
      dispatch({
        type: 'initiative_1',
        initiative: `0${initiative1}`,
      });
    }
  }, [dispatch, initiative1]);

  const handleInitiative2Blur = useCallback(() => {
    if (ignoreNextBlur) {
      ignoreNextBlur = false;
      return;
    }

    if (initiative2.length === 1 && initiative2 !== '0') {
      dispatch({
        type: 'initiative_2',
        initiative: `0${initiative2}`,
      });
    }
  }, [dispatch, initiative2]);

  const processInitiative1ChangeInput = useCallback(
    (input: string) => {
      const validatedInput = validateInitiativeInput(input);

      if (validatedInput !== null) {
        dispatch({
          type: 'initiative_1',
          initiative: validatedInput,
        });

        if (validatedInput.length >= 2) {
          ignoreNextBlur = true;
          if (initiative2.length < 2) {
            secondInputRef.current?.focus();
          }
        }
      }
    },
    [dispatch, initiative2.length, secondInputRef],
  );

  const processInitiative2ChangeInput = useCallback(
    (input: string) => {
      const validatedInput = validateInitiativeInput(input);

      if (validatedInput !== null) {
        dispatch({
          type: 'initiative_2',
          initiative: validatedInput,
        });

        if (validatedInput.length >= 2) {
          ignoreNextBlur = true;
          if (initiative1.length < 2) {
            firstInputRef.current?.focus();
          }
        }
      }
    },
    [dispatch, firstInputRef, initiative1.length],
  );

  const handleInitiative1Change = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      if (initiative1.length === 2 && value.length > 2) {
        secondInputRef.current?.focus();
        return processInitiative2ChangeInput(value.slice(2));
      }

      return processInitiative1ChangeInput(value);
    },
    [initiative1.length, processInitiative1ChangeInput, processInitiative2ChangeInput, secondInputRef],
  );

  const handleInitiative2Change = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      return processInitiative2ChangeInput(value);
    },
    [processInitiative2ChangeInput],
  );

  const handleInitiativeKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!/\d|Backspace|Delete|Enter|ArrowLeft|ArrowRight|Shift|Tab/.test(event.key)) {
      event.preventDefault();
    }
  }, []);

  const handleInitiative1KeyDown = useCallback(handleInitiativeKeyDown, [handleInitiativeKeyDown]);

  const handleInitiative2KeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Backspace' && initiative2 === '') {
        firstInputRef.current?.focus();
      }

      handleInitiativeKeyDown(event);
    },
    [firstInputRef, handleInitiativeKeyDown, initiative2],
  );

  const getInitativeValue = useCallback(
    (initiative: 1 | 2) => {
      if (hideInitiatives) {
        return '••';
      }

      if (longResting) {
        return '';
      }

      return initiative === 1 ? initiative1 : initiative2;
    },
    [hideInitiatives, initiative1, initiative2, longResting],
  );

  const initiative1Value = useMemo(() => getInitativeValue(1), [getInitativeValue]);
  const initiative2Value = useMemo(() => getInitativeValue(2), [getInitativeValue]);

  return (
    <Box display="flex" gap={2}>
      <Grid container flexShrink={1} spacing={2}>
        <Grid item md={6} xs={12}>
          <Box
            sx={{
              cursor: ready ? 'pointer' : 'initial',
            }}
            onMouseDown={handleInitiativeMouseDown}
            onMouseLeave={handleInitiativeMouseLeave}
            onMouseUp={handleInitiativeMouseUp}
            onTouchEnd={handleInitiativeTouchEnd}
            onTouchStart={handleInitiativeTouchStart}
          >
            <TextField
              fullWidth
              inputMode={hideInitiatives ? 'none' : 'numeric'}
              inputProps={{
                min: 1,
                max: 99,
                pattern: '[0-9]*',
                readOnly: ready,
                ref: firstInputRef,
              }}
              label={longResting ? 'Long Resting' : 'Initiative 1'}
              placeholder={longResting ? 'Long Resting' : 'Initiative 1'}
              required
              sx={{
                pointerEvents: ready ? 'none' : 'initial',
              }}
              type={hideInitiatives || ready ? 'text' : 'number'}
              value={initiative1Value}
              onBlur={handleInitiative1Blur}
              onChange={handleInitiative1Change}
              onKeyDown={handleInitiative1KeyDown}
            />
          </Box>
        </Grid>

        <Grid item md={6} xs={12}>
          <Box
            sx={{
              cursor: ready ? 'pointer' : 'initial',
            }}
            onMouseDown={handleInitiativeMouseDown}
            onMouseLeave={handleInitiativeMouseLeave}
            onMouseUp={handleInitiativeMouseUp}
            onTouchEnd={handleInitiativeTouchEnd}
            onTouchStart={handleInitiativeTouchStart}
          >
            <TextField
              fullWidth
              inputMode={hideInitiatives ? 'none' : 'numeric'}
              inputProps={{
                min: 1,
                max: 99,
                pattern: '[0-9]*',
                readOnly: ready,
                ref: secondInputRef,
              }}
              label={longResting ? 'Long Resting' : 'Initiative 2'}
              placeholder={longResting ? 'Long Resting' : 'Initiative 2'}
              required
              sx={{
                pointerEvents: ready ? 'none' : 'initial',
              }}
              type={hideInitiatives || ready ? 'text' : 'number'}
              value={initiative2Value}
              onBlur={handleInitiative2Blur}
              onChange={handleInitiative2Change}
              onKeyDown={handleInitiative2KeyDown}
            />
          </Box>
        </Grid>
      </Grid>

      <Button disabled={ready} variant="outlined" onClick={handleSwapInitiatives}>
        <SwapVertIcon />
      </Button>
    </Box>
  );
}
