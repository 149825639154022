import { useMemo } from 'react';

import { useRoomState } from './useRoomState';

export function useMissing(playerUuid?: string) {
  const { state } = useRoomState();
  const { missingPlayers, uuid } = state;

  const targetUuid = playerUuid ?? uuid;

  return useMemo(() => missingPlayers.has(targetUuid), [missingPlayers, targetUuid]);
}
