import type { GloomhavenClassName } from '@common/misc';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Grid from '@mui/material/Grid';
import { useColorMode } from '@src/hooks/useColorMode';
import { useCallback, useMemo, useState } from 'react';

import bruteIcon from '../assets/gloomhaven_class_icons/01_brute.png';
import tinkererIcon from '../assets/gloomhaven_class_icons/02_tinkerer.png';
import spellweaverIcon from '../assets/gloomhaven_class_icons/03_spellweaver.png';
import scoundrelIcon from '../assets/gloomhaven_class_icons/04_scoundrel.png';
import cragheartIcon from '../assets/gloomhaven_class_icons/05_cragheart.png';
import mindthiefIcon from '../assets/gloomhaven_class_icons/06_mindthief.png';
import sunIcon from '../assets/gloomhaven_class_icons/07_sun.png';
import threeSpearsIcon from '../assets/gloomhaven_class_icons/08_three_spears.png';
import circlesIcon from '../assets/gloomhaven_class_icons/09_circles.png';
import eclipseIcon from '../assets/gloomhaven_class_icons/10_eclipse.png';
import squidfaceIcon from '../assets/gloomhaven_class_icons/11_squidface.png';
import lightningIcon from '../assets/gloomhaven_class_icons/12_lightning.png';
import musicnoteIcon from '../assets/gloomhaven_class_icons/13_musicnote.png';
import angryfaceIcon from '../assets/gloomhaven_class_icons/14_angryface.png';
import sawIcon from '../assets/gloomhaven_class_icons/15_saw.png';
import trianglesIcon from '../assets/gloomhaven_class_icons/16_triangles.png';
import twoMiniIcon from '../assets/gloomhaven_class_icons/17_two-mini.png';

type ClassIconCardProps = {
  name: GloomhavenClassName;
  selected: boolean;
  onClick: (name: GloomhavenClassName) => unknown;
};

export const classIcons: Record<GloomhavenClassName, string> = {
  brute: bruteIcon,
  tinkerer: tinkererIcon,
  spellweaver: spellweaverIcon,
  scoundrel: scoundrelIcon,
  cragheart: cragheartIcon,
  mindthief: mindthiefIcon,
  sun: sunIcon,
  threeSpears: threeSpearsIcon,
  circles: circlesIcon,
  eclipse: eclipseIcon,
  squidface: squidfaceIcon,
  lightnint: lightningIcon,
  musicnote: musicnoteIcon,
  angryface: angryfaceIcon,
  saw: sawIcon,
  triangles: trianglesIcon,
  twoMini: twoMiniIcon,
};

type ClassSelectorProps = {
  onChange: (name: GloomhavenClassName) => unknown;
};

export function ClassSelector({ onChange }: ClassSelectorProps) {
  const [classIcon, setClassIcon] = useState<GloomhavenClassName | null>(null);

  const handleClassIconClick = useCallback(
    (name: GloomhavenClassName) => {
      setClassIcon(name);
      onChange(name);
    },
    [onChange],
  );

  return (
    <Grid container justifyContent="center" spacing={1}>
      {Object.keys(classIcons).map((name) => (
        <ClassIconCard
          key={name}
          name={name as GloomhavenClassName}
          selected={classIcon === name}
          onClick={handleClassIconClick}
        />
      ))}
    </Grid>
  );
}

function ClassIconCard({ name, onClick, selected }: ClassIconCardProps) {
  const handleClick = useCallback(() => onClick(name), [name, onClick]);

  return (
    <Grid key={name} item>
      <Card elevation={selected ? 12 : 1} sx={{ height: '72px' }}>
        <CardActionArea onClick={handleClick}>
          <Box padding="12px">
            <ClassIcon name={name} size={48} />
          </Box>
        </CardActionArea>
      </Card>
    </Grid>
  );
}

type ClassIconProps = {
  name: GloomhavenClassName;
  size: number;
};

export function ClassIcon({ name, size }: ClassIconProps) {
  const { mode } = useColorMode();

  const icon = useMemo(() => classIcons[name], [name]);

  return (
    <Box display="inline-flex" sx={{ filter: `invert(${mode === 'dark' ? 1 : 0})` }}>
      <img alt={`${name} class icon`} height={size} src={icon} width={size} />
    </Box>
  );
}
