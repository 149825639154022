import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useIsMobile } from '@src/hooks/useIsMobile';
import type { ComponentProps } from 'react';

type MobileFriendlyDialogTitleProps = ComponentProps<typeof DialogTitle> & {
  onClose: () => unknown;
};

export function MobileFriendlyDialogTitle({ children, onClose, ...otherProps }: MobileFriendlyDialogTitleProps) {
  const isMobile = useIsMobile();

  if (!isMobile) {
    return <DialogTitle {...otherProps}>{children}</DialogTitle>;
  }

  return (
    <DialogTitle {...otherProps}>
      <Stack alignItems="center" direction="row" gap="4px" justifyContent="space-between">
        {children ?? <Box />}
        <Box>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Stack>
    </DialogTitle>
  );
}
