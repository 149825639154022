import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';

function NotFound(): JSX.Element {
  const navigate = useNavigate();

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const goHome = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <Container>
      <Stack gap="16px" paddingBottom="24px" paddingTop="24px">
        <Typography textAlign="center" variant="h3">
          ddduel
        </Typography>

        <Typography textAlign="center" variant="h5">
          404 - Page not found
        </Typography>

        <Stack direction="row" gap="24px" justifyContent="center">
          <Button variant="outlined" onClick={goHome}>
            Go home
          </Button>

          <Button variant="outlined" onClick={goBack}>
            Go back
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
}

export default NotFound;
