import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useLongResting } from '@src/hooks/useLongResting';
import { usePromptConfirmDialog } from '@src/hooks/usePromptConfirmDialog';
import { useRoomState } from '@src/hooks/useRoomState';
import React, { useCallback } from 'react';

type ButtonsRowProps = {
  readyButtonRef: React.RefObject<HTMLButtonElement>;
};

export function ButtonsRow({ readyButtonRef }: ButtonsRowProps) {
  const { dispatch, state } = useRoomState();
  const { ready, socket } = state;

  const { promptConfirmDialog } = usePromptConfirmDialog();

  const longResting = useLongResting();

  const handleExhaustedClick = useCallback(async () => {
    const confirmed = await promptConfirmDialog({ title: "Are you sure that you're exhausted?" });
    if (!confirmed) {
      return;
    }

    socket?.emit('exhaust');
  }, [promptConfirmDialog, socket]);

  const handleLongRestClick = useCallback(() => {
    if (longResting) {
      dispatch({ type: 'reset_initiatives' });
      socket?.emit('unready');

      return;
    }

    dispatch({ type: 'long_rest' });
    socket?.emit('ready', [99, 99]);
  }, [dispatch, longResting, socket]);

  return (
    <Stack alignItems="center">
      <Stack gap="16px" width="min-content">
        <Button ref={readyButtonRef} disabled={longResting} size="large" type="submit" variant="outlined">
          {ready && !longResting ? 'Unready' : 'Ready'}
        </Button>

        <Button
          disabled={ready && !longResting}
          size="large"
          type="button"
          variant="outlined"
          onClick={handleLongRestClick}
        >
          {longResting ? 'Cancel' : 'Long Rest'}
        </Button>

        <Button
          color="warning"
          disabled={ready}
          size="large"
          type="button"
          variant="outlined"
          onClick={handleExhaustedClick}
        >
          Exhausted
        </Button>
      </Stack>
    </Stack>
  );
}
