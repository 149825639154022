import type React from 'react';
import { useEffect, useState } from 'react';

/**
 * `useAsyncMemo` will only recompute tjeh memoized value when one of the `deps` has changed.
 *
 * Usage note: if calling `useAsyncMemo` with a referentially stable function,
 * also give it as the input in the second argument
 * @param initialValue - The initial value to use before the factory returns
 * @see https://reactjs.org/docs/hooks-reference.html#usememo
 */
export default function useAsyncMemo<T>(factory: () => Promise<T>, initialValue: T, deps: React.DependencyList = []) {
  const [value, setValue] = useState<T>(() => initialValue);

  useEffect(() => {
    let expired = false;

    async function runFactory() {
      const newValue = await factory();

      if (!expired) {
        setValue(newValue);
      }
    }

    runFactory();

    return () => {
      expired = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [factory, ...deps]);

  return value;
}
