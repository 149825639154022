import type { PaletteMode } from '@mui/material';
import { createTheme, CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material';
import useEffectOnMount from '@src/hooks/useEffectOnMount';
import { createContext, useCallback, useMemo, useState } from 'react';

export const ColorModeContext = createContext<null | {
  mode: 'dark' | 'light';
  toggleMode: () => void;
}>(null);

type AppThemeProviderProps = {
  children: React.ReactNode;
};

export function AppThemeProvider({ children }: AppThemeProviderProps) {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const [mode, setMode] = useState<PaletteMode>(prefersDarkMode ? 'dark' : 'light');

  useEffectOnMount(() => {
    const savedMode = localStorage.getItem('theme-mode');
    if (savedMode === 'light' || savedMode === 'dark') {
      setMode(savedMode);
    } else {
      localStorage.setItem('theme-mode', prefersDarkMode ? 'dark' : 'light');
    }
  });

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode],
  );

  const toggleMode = useCallback(() => {
    const newMode = mode === 'dark' ? 'light' : 'dark';
    localStorage.setItem('theme-mode', newMode);
    setMode(newMode);
  }, [mode]);

  const value = useMemo(
    () => ({
      mode,
      toggleMode,
    }),
    [mode, toggleMode],
  );

  return (
    <ColorModeContext.Provider value={value}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
