import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useExhausted } from '@src/hooks/useExhausted';
import { usePromptConfirmDialog } from '@src/hooks/usePromptConfirmDialog';
import { useRoomState } from '@src/hooks/useRoomState';
import axios from 'axios';
import log from 'loglevel';
import { useCallback, useEffect, useState } from 'react';

import { MobileFriendlyDialog } from './MobileFriendlyDialog';
import { MobileFriendlyDialogTitle } from './MobileFriendlyDialogTitle';

type RenameDialogProps = {
  close: () => void;
  onSave: (newUsername: string) => Promise<boolean>;
  open: boolean;
};

function RenameDialog({ close, onSave, open }: RenameDialogProps): JSX.Element {
  const { state } = useRoomState();
  const { socket, username } = state;

  const { promptConfirmDialog } = usePromptConfirmDialog();

  const exhausted = useExhausted();

  const [newUsername, setNewUsername] = useState('');

  useEffect(() => setNewUsername(''), [username]);

  const handleDialogClose = useCallback(() => {
    setNewUsername('');
    close();
  }, [close]);

  const handleRenameSave = useCallback(async () => {
    const success = await onSave(newUsername);

    if (success) {
      close();
    }
  }, [close, newUsername, onSave]);

  const handleFormSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      log.debug('Form submitted');
      handleRenameSave();
    },
    [handleRenameSave],
  );

  const handleRetconExhaustionClick = useCallback(async () => {
    const confirmed = await promptConfirmDialog({ title: "Are you sure that you're actually exhausted?" });
    if (!confirmed) {
      return;
    }

    socket?.emit('retconExhaustion');
    handleDialogClose();
  }, [handleDialogClose, promptConfirmDialog, socket]);

  const handleLeaveClick = useCallback(async () => {
    const confirmed = await promptConfirmDialog({ title: "Are you sure you'd like to leave the room?" });

    if (confirmed) {
      try {
        await axios.post('/leaveRoom');
      } catch (error) {
        log.error(error);
      }
    }
  }, [promptConfirmDialog]);

  const handleNewUsernameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setNewUsername(event.target.value);
  }, []);

  return (
    <MobileFriendlyDialog open={open} onClose={handleDialogClose}>
      <MobileFriendlyDialogTitle onClose={handleDialogClose}>Options</MobileFriendlyDialogTitle>

      <DialogContent>
        <form onSubmit={handleFormSubmit}>
          <Stack direction="row" gap="16px" justifyContent="space-between" paddingTop="6px">
            <TextField
              autoFocus
              fullWidth
              label="New username"
              required
              value={newUsername}
              onChange={handleNewUsernameChange}
            />
            <Button type="button" variant="outlined" onClick={handleRenameSave}>
              Save
            </Button>
          </Stack>
        </form>
      </DialogContent>

      <DialogActions>
        <Stack direction="row" gap="16px">
          <Button color="error" type="button" variant="outlined" onClick={handleLeaveClick}>
            Leave Room
          </Button>
          {exhausted && (
            <Button color="success" type="button" variant="outlined" onClick={handleRetconExhaustionClick}>
              Retcon exhaustion
            </Button>
          )}
        </Stack>
      </DialogActions>
    </MobileFriendlyDialog>
  );
}

export default RenameDialog;
