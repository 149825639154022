import type { GloomhavenClassName } from '@common/misc';
import { faCrown, faTowerBroadcast } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useColorMode } from '@src/hooks/useColorMode';
import { useExhausted } from '@src/hooks/useExhausted';
import { useMissing } from '@src/hooks/useMissing';
import { useRoomState } from '@src/hooks/useRoomState';
import { styled } from 'linaria/react';
import { useMemo } from 'react';

import { ClassIcon } from './ClassIcons';
import ReadyIcon from './ReadyIcon';

interface PlayerProps {
  disabled: boolean;
  onClick: () => void;
  playerClass: GloomhavenClassName;
  playerUuid: string;
  playerUsername: string;
}

const pulse = `
  @keyframes pulse {
    0%, 100% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }
  }
`;

const Pulse = styled.div`
  ${pulse}

  animation-direction: normal;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-name: pulse;
  animation-timing-function: ease-in-out;
`;

export function Player({ disabled, onClick, playerClass, playerUsername, playerUuid }: PlayerProps) {
  const { state } = useRoomState();
  const { host } = state;

  const exhausted = useExhausted(playerUuid);
  const disconnected = useMissing(playerUuid) && !exhausted;

  const { mode } = useColorMode();

  const textStyles = useMemo(() => {
    const base = {
      wordBreak: 'break-all',
    };

    if (disabled) {
      return base;
    }

    return {
      ...base,
      cursor: 'pointer',
      '&:hover': {
        color: '#ccc',
      },
    };
  }, [disabled]);

  const handleClick = useMemo(() => (disabled ? undefined : onClick), [disabled, onClick]);

  return (
    <Stack alignItems="center" direction="row" gap="4px">
      <ClassIcon name={playerClass} size={16} />

      <ReadyIcon playerUuid={playerUuid} />

      <Typography alignItems="baseline" sx={textStyles} onClick={handleClick}>
        {playerUsername}
      </Typography>

      {host === playerUuid && (
        <Box color="gold">
          <FontAwesomeIcon icon={faCrown} />
        </Box>
      )}

      {disconnected && (
        <Pulse>
          <FontAwesomeIcon icon={faTowerBroadcast} inverse={mode === 'dark'} />
        </Pulse>
      )}
    </Stack>
  );
}
