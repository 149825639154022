import type { RoomState } from '@src/Reducers/RoomReducer';
import { getInitialRoomState, roomReducer } from '@src/Reducers/RoomReducer';
import type { RoomAction } from '@src/Reducers/RoomReducer/actions';
import React, { createContext, useMemo, useReducer } from 'react';

export const RoomStateContext = createContext<RoomStateValue | null>(null);

type RoomStateValue = {
  state: RoomState;
  dispatch: React.Dispatch<RoomAction>;
};

export function RoomStateProvider({ children }: { children: React.ReactNode }) {
  const initialRoomState = useMemo(() => getInitialRoomState(), []);
  const [state, dispatch] = useReducer(roomReducer, initialRoomState);

  const value = useMemo(() => ({ dispatch, state }), [state]);

  return <RoomStateContext.Provider value={value}>{children}</RoomStateContext.Provider>;
}
