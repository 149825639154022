import { ConfirmDialogContext } from '@src/Providers/ConfirmDialogProvider';
import { useContext } from 'react';

export function usePromptConfirmDialog() {
  const promptConfirmDialog = useContext(ConfirmDialogContext);

  if (promptConfirmDialog === null) {
    throw new Error('usePromptConfirmDialog called without ConfirmDialogProvider');
  }

  return promptConfirmDialog;
}
