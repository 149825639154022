import type { PlayerInitiatives } from '../misc';
import type { RoomPlayerData } from '../RoomPlayerData';

export function sortPlayerInitiatives(playerData: RoomPlayerData): PlayerInitiatives[] {
  return Object.values(playerData)
    .map<PlayerInitiatives>(({ initiatives, uuid }) => [uuid, ...initiatives])
    .sort((a, b) => {
      if (a[1] < b[1]) return -1;

      if (b[1] < a[1]) return 1;

      if (a[2] < b[2]) return -1;

      if (b[2] < a[2]) return 1;

      return 0;
    });
}
