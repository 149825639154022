import log from 'loglevel';
import { createElement } from 'react';
import { render } from 'react-dom';

import App from './Components/App';

log.setDefaultLevel('debug');

const rootEl = document.getElementById('root');

render(createElement(App), rootEl);
