import type { GloomhavenClassName } from '@common/misc';
import type PlayerId from '@common/PlayerId';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { useRoomState } from '@src/hooks/useRoomState';
import axios from 'axios';
import log from 'loglevel';
import { useCallback, useMemo, useState } from 'react';

import OtherPlayerDialog from './OtherPlayerDialog';
import { Player } from './Player';

type RoomPlayersProps = {
  openRenameDialog: () => void;
  roomCode: string;
  uuid: string;
};

function RoomPlayersRow({ openRenameDialog, roomCode, uuid }: RoomPlayersProps) {
  const { state } = useRoomState();
  const { allPlayers } = state;

  const [dialogPlayerId, setDialogPlayerId] = useState<PlayerId | null>(null);

  const hideDialog = useCallback(() => setDialogPlayerId(null), []);

  const kickPlayer = useCallback(
    async (playerUuid: string) => {
      try {
        await axios.post('/kickPlayer', {
          roomCode,
          player: playerUuid,
        });
      } catch (error) {
        log.error(error);
      }

      setDialogPlayerId(null);
    },
    [roomCode],
  );

  const onPlayerClick = useCallback(
    ({ className, username, uuid: playerUuid }: PlayerId) => {
      if (playerUuid === uuid) {
        openRenameDialog();

        return;
      }

      setDialogPlayerId({ className, username, uuid: playerUuid });
    },
    [openRenameDialog, uuid],
  );

  return (
    <Grid container>
      {Object.keys(allPlayers).length === 0 && <Hidden>Loading...</Hidden>}
      {Object.keys(allPlayers).map((playerUuid) => {
        const { className: playerClass, username: playerUsername } = allPlayers[playerUuid];
        return (
          <PlayerCol
            key={playerUuid}
            playerClass={playerClass}
            playerUsername={playerUsername}
            playerUuid={playerUuid}
            onPlayerClick={onPlayerClick}
          />
        );
      })}

      {dialogPlayerId && <OtherPlayerDialog playerId={dialogPlayerId} onClose={hideDialog} onKickPlayer={kickPlayer} />}
    </Grid>
  );
}

type PlayerColProps = {
  onPlayerClick(options: { username: string; uuid: string }): void;
  playerClass: GloomhavenClassName;
  playerUsername: string;
  playerUuid: string;
};

function PlayerCol({ onPlayerClick, playerClass, playerUsername, playerUuid }: PlayerColProps) {
  const { state } = useRoomState();
  const { host, uuid } = state;

  const handleOnPlayerClick = useCallback(() => {
    onPlayerClick({ username: playerUsername, uuid: playerUuid });
  }, [onPlayerClick, playerUsername, playerUuid]);

  const clickDisabled = useMemo(() => ![host, playerUuid].includes(uuid), [host, playerUuid, uuid]);

  return (
    <Grid key={playerUuid} item md={4} paddingBottom="12px" xs={6}>
      <Player
        disabled={clickDisabled}
        playerClass={playerClass}
        playerUsername={playerUsername}
        playerUuid={playerUuid}
        onClick={handleOnPlayerClick}
      />
    </Grid>
  );
}

export default RoomPlayersRow;
