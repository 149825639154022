import { styled } from 'linaria/lib/react';
import { useEffect } from 'react';

import shuffleAudio from '../assets/card-shuffle.wav';
import ddduelGif from '../assets/ddduel.gif';
import ddduelAudio from '../assets/ddduel.mp3';
import yourMoveGif from '../assets/your-move.gif';
import type { RoomState } from '../Reducers/RoomReducer';

type DdduelGifProps = {
  show: RoomState['showRoundResults'];
};

export default function DdduelGif({ show }: DdduelGifProps) {
  return show === 'current' ? <CurrentRound /> : <LastRound />;
}

function CurrentRound() {
  useEffect(() => {
    const audio = new Audio(ddduelAudio);

    audio.play();

    return () => audio.pause();
  }, []);

  return <ContainedImg alt="Yugi dueling gif" id="ddduel-gif" src={ddduelGif} />;
}

function LastRound() {
  useEffect(() => {
    const audio = new Audio(shuffleAudio);

    audio.play();

    return () => audio.pause();
  }, []);

  return (
    <LastRoundContainer>
      <ContainedImg alt="Yugi shuffing gif" id="your-move-gif" src={yourMoveGif} />
    </LastRoundContainer>
  );
}

const LastRoundContainer = styled.div`
  display: flex;
  justify-content: center;

  img {
    width: 100%;
  }
`;

const ContainedImg = styled.img`
  max-height: 100%;
  max-width: 100%;
`;
