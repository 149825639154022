/* eslint-disable react-hooks/exhaustive-deps */
import type { RoomClientEventsMap } from '@common/events/RoomClientEvents';
import type { PlayerRoomState, RoomServerEventsMap } from '@common/events/RoomServerEvents';
import type { PlayerInitiatives } from '@common/misc';
import type PlayerId from '@common/PlayerId';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useEffectOnMount from '@src/hooks/useEffectOnMount';
import { useExhausted } from '@src/hooks/useExhausted';
import { useRoomCode } from '@src/hooks/useRoomCode';
import { useRoomState } from '@src/hooks/useRoomState';
import { serverLog } from '@src/utils/serverLog';
import axios, { type AxiosError } from 'axios';
import log from 'loglevel';
import { useSnackbar } from 'notistack';
import React, { createRef, useCallback, useEffect, useRef, useState } from 'react';
import { useBlocker, useNavigate } from 'react-router-dom';
import { type Socket, io } from 'socket.io-client';

import RenameDialog from '../RenameDialog';
import RoomCodeDialog from '../RoomCodeDialog';
import RoomPlayersRow from '../RoomPlayersRow';
import RoundResultDialog from '../RoundResultDialog';
import { ButtonsRow } from './ButtonsRow';
import { InitiativesRow } from './InitiativesRow';

type ClientSocket = Socket<RoomServerEventsMap, RoomClientEventsMap>;

function Room(): JSX.Element {
  const navigate = useNavigate();
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  const roomCode = useRoomCode();
  const { dispatch, state } = useRoomState();
  const {
    allReadyData,
    initiatives: [initiative1, initiative2],
    ready,
    roundNumber,
    socket,
    uuid,
  } = state;

  const exhausted = useExhausted();

  const roundNumberRef = useRef<number | null>(state.roundNumber);
  useEffect(() => {
    roundNumberRef.current = roundNumber;
  }, [roundNumber]);

  const firstInputRef = createRef<HTMLInputElement>();
  const secondInputRef = createRef<HTMLInputElement>();
  const readyButtonRef = createRef<HTMLButtonElement>();

  const [showRenameDialog, setShowRenameDialog] = useState(false);
  const [showRoomCodeDialog, setShowRoomCodeDialog] = useState(false);
  const [showRoundResults, setShowRoundResults] = useState<null | 'last' | 'current'>(null);

  const handleRoundResultDialogClose = useCallback(() => {
    setShowRoundResults(null);

    firstInputRef.current?.focus();
  }, [dispatch, firstInputRef]);

  useEffectOnMount(() => {
    if (!ready) {
      firstInputRef.current?.focus();
    }
  });

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);

  const blocker = useBlocker(true);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      // eslint-disable-next-line no-alert
      const confirmed = window.confirm('Are you sure you\'d like to leave this page?');
      if (confirmed) {
        blocker.proceed();
      } else {
        blocker.reset();
      }
    }
  }, [blocker])

  const hideRenameDialog = useCallback(() => setShowRenameDialog(false), []);

  const showCurrentRound = useCallback(() => setShowRoundResults('current'), []);

  const handleAllPlayersAddEvent = useCallback(
    (player: PlayerId) =>
      dispatch({
        type: 'all_players_add',
        player,
      }),
    [],
  );

  const handleAllPlayersRemoveEvent = useCallback(
    (playerUuid: string) =>
      dispatch({
        type: 'all_players_remove',
        uuid: playerUuid,
      }),
    [],
  );

  const handleAllReadyEvent = useCallback(
    (positions: PlayerInitiatives[]) => {
      dispatch({
        type: 'all_ready',
        playerInitiatives: positions,
      });

      showCurrentRound();

      dispatch({ type: 'reset_initiatives' });
    },
    [showCurrentRound],
  );

  const handleDisconnectEvent = useCallback(
    () => enqueueSnackbar('Lost connection to room', { persist: true, variant: 'warning' }),
    [],
  );

  const handleExhaustedPlayersAddEvent = useCallback((playerUuid: string) => {
    dispatch({
      type: 'exhausted_players_add',
      uuid: playerUuid,
    });
  }, []);

  const handleExhaustedPlayersRemoveEvent = useCallback((playerUuid: string) => {
    dispatch({
      type: 'exhausted_players_remove',
      uuid: playerUuid,
    });
  }, []);

  const handleHostEvent = useCallback(
    (newHost: string) =>
      dispatch({
        type: 'host',
        uuid: newHost,
      }),
    [],
  );

  const handleLastInitiativesEvent = useCallback((playerInitiatives: PlayerInitiatives[]) => {
    dispatch({
      type: 'all_ready',
      playerInitiatives,
    });
  }, []);

  const handleMissingPlayersAddEvent = useCallback(
    (playerUuid: string) =>
      dispatch({
        type: 'missing_players_add',
        uuid: playerUuid,
      }),
    [],
  );

  const handleMissingPlayersRemoveEvent = useCallback(
    (playerUuid: string) =>
      dispatch({
        type: 'missing_players_remove',
        uuid: playerUuid,
      }),
    [],
  );

  const handleReadyPlayersAddEvent = useCallback(
    (playerUuid: string) =>
      dispatch({
        type: 'ready_players_add',
        uuid: playerUuid,
      }),
    [],
  );

  const handleReadyPlayersRemoveEvent = useCallback(
    (playerUuid: string) =>
      dispatch({
        type: 'ready_players_remove',
        uuid: playerUuid,
      }),
    [],
  );

  const handleReadyPlayersResetEvent = useCallback(
    () =>
      dispatch({
        type: 'ready_players_reset',
      }),
    [],
  );

  const handleReconnectEvent = useCallback((newSocket) => {
    serverLog('resync for reconnect');
    newSocket.emit('resync');
    closeSnackbar();
  }, []);

  const handleRemovedFromRoomEvent = useCallback(
    (reason: string) => {
      log.debug('Removed from room', reason);

      enqueueSnackbar(`Removed from room - ${reason}`, {
        variant: 'warning',
      });

      navigate('/');
      dispatch({ type: 'reset_state' });
    },
    [navigate],
  );

  const handleResyncEvent = useCallback(
    (resyncState: PlayerRoomState) => {
      log.info('Received resync state', resyncState);
      dispatch({
        type: 'resync',
        state: resyncState,
      });

      if (
        roundNumberRef.current !== null &&
        state.roundNumber !== null &&
        state.roundNumber !== roundNumberRef.current
      ) {
        showCurrentRound();
      }
    },
    [showCurrentRound, state.roundNumber],
  );

  const handleRoomNotFoundEvent = useCallback(() => {
    navigate(`/roomNotFound/${roomCode}`);
    dispatch({ type: 'reset_state' });
  }, []);

  const handleRoundNumberEvent = useCallback(
    (round) =>
      dispatch({
        type: 'round_number',
        roundNumber: round,
      }),
    [],
  );

  const handleUsernameChangeEvent = useCallback(
    (playerUuid: string, username: string) =>
      dispatch({
        type: 'username_change',
        username,
        uuid: playerUuid,
      }),
    [],
  );

  const initializeSocket = useCallback(() => {
    // Prevents connect events from not having a matching disconnect event
    // https://stackoverflow.com/a/41953165
    const initSocket: ClientSocket = io({
      transports: ['websocket'],
      query: { roomCode },
      upgrade: false,
    });

    dispatch({
      type: 'socket',
      socket: initSocket,
    });

    initSocket.on('all_ready', handleAllReadyEvent);
    initSocket.on('all_players_add', handleAllPlayersAddEvent);
    initSocket.on('all_players_remove', handleAllPlayersRemoveEvent);
    initSocket.on('disconnect', handleDisconnectEvent);
    initSocket.on('exhausted_players_add', handleExhaustedPlayersAddEvent);
    initSocket.on('exhausted_players_remove', handleExhaustedPlayersRemoveEvent);
    initSocket.on('host', handleHostEvent);
    initSocket.on('last_initiatives', handleLastInitiativesEvent);
    initSocket.on('missing_players_add', handleMissingPlayersAddEvent);
    initSocket.on('missing_players_remove', handleMissingPlayersRemoveEvent);
    initSocket.on('ready_players_add', handleReadyPlayersAddEvent);
    initSocket.on('ready_players_remove', handleReadyPlayersRemoveEvent);
    initSocket.on('ready_players_reset', handleReadyPlayersResetEvent);
    initSocket.io.on('reconnect', () => handleReconnectEvent(initSocket));
    initSocket.on('removed_from_room', handleRemovedFromRoomEvent);
    initSocket.on('resync', handleResyncEvent);
    initSocket.on('room_not_found', handleRoomNotFoundEvent);
    initSocket.on('round_number', handleRoundNumberEvent);
    initSocket.on('username_change', handleUsernameChangeEvent);

    serverLog('resync for init');
    initSocket.emit('resync');

    return initSocket;
  }, [
    roomCode,
    handleAllReadyEvent,
    handleDisconnectEvent,
    handleHostEvent,
    handleLastInitiativesEvent,
    handleReconnectEvent,
    handleRemovedFromRoomEvent,
    handleResyncEvent,
    handleRoomNotFoundEvent,
  ]);

  const onFormSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();

      if (ready) {
        dispatch({
          type: 'ready',
          ready: false,
        });
        socket?.emit('unready');

        return;
      }

      dispatch({
        type: 'ready',
        ready: true,
      });

      const initiativeValue1 = Number(initiative1);
      const initiativeInput2 = Number(initiative2);
      const initiativeValue2 = initiativeInput2 === 0 ? 99 : initiativeInput2;

      socket?.emit('ready', [initiativeValue1, initiativeValue2]);
    },
    [initiative1, initiative2, ready, socket],
  );

  const handleRenameDialogSave = useCallback(async (newUsername: string) => {
    const sanitized = newUsername.trim();

    try {
      await axios.put('/username', { username: sanitized });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;

        if (axiosError.response?.status === 400) {
          enqueueSnackbar('This username is taken', { variant: 'error' });
          return false;
        }
      }

      log.error(error);
      enqueueSnackbar('Error changing name', { variant: 'error' });

      return false;
    }

    setShowRenameDialog(false);

    return true;
  }, []);

  const handleRoomCodeDialogClose = useCallback(() => setShowRoomCodeDialog(false), []);

  const openRenameDialog = useCallback(() => setShowRenameDialog(true), []);

  const openShowRoomCodeDialog = useCallback(() => setShowRoomCodeDialog(true), []);

  const showLastRound = useCallback(() => setShowRoundResults('last'), []);

  useEffectOnMount(() => {
    let initSocket: ClientSocket;

    const ensureConnected = () => {
      if (!initSocket.connected) {
        initSocket.connect();
        serverLog('resync for ensureConnected');
        initSocket.emit('resync');
      }
    };

    async function confirmInRoom(): Promise<void> {
      try {
        const response = await axios.get(`/inRoom/${roomCode}`);

        if (!response.data as boolean) {
          navigate(`/joinRoom/${roomCode}`, { replace: true });
          dispatch({ type: 'reset_state' });

          return;
        }

        window.addEventListener('focus', ensureConnected);
      } catch (error) {
        log.error(error);
        enqueueSnackbar('Error connecting to room', { variant: 'error' });

        return;
      }

      initSocket = initializeSocket();
    }

    confirmInRoom();

    return () => {
      window.removeEventListener('focus', ensureConnected);
      initSocket?.removeAllListeners();
      initSocket?.disconnect();
    };
  });

  return (
    <>
      <Container>
        <Stack gap="16px" justifyContent="center" paddingBottom="24px" paddingTop="24px">
          <Stack direction="row" gap="16px" justifyContent="center">
            <Button size="small" variant="outlined" onClick={openShowRoomCodeDialog}>
              Show room code
            </Button>
            {allReadyData.length !== 0 && (
              <Button size="small" variant="outlined" onClick={showLastRound}>
                Show last round
              </Button>
            )}
          </Stack>

          <RoomPlayersRow openRenameDialog={openRenameDialog} roomCode={roomCode} uuid={uuid} />

          <Typography textAlign="center" variant="h5">
            Round {roundNumber}
          </Typography>

          {!exhausted && (
            <form onSubmit={onFormSubmit}>
              <Stack gap="32px">
                <InitiativesRow firstInputRef={firstInputRef} secondInputRef={secondInputRef} />

                <ButtonsRow readyButtonRef={readyButtonRef} />
              </Stack>
            </form>
          )}

          {exhausted && (
            <Typography textAlign="center" variant="h6">
              Have a nice nap.
            </Typography>
          )}
        </Stack>
      </Container>

      <RenameDialog close={hideRenameDialog} open={showRenameDialog} onSave={handleRenameDialogSave} />

      <RoomCodeDialog show={showRoomCodeDialog} onClose={handleRoomCodeDialogClose} />

      <RoundResultDialog show={showRoundResults} onClose={handleRoundResultDialogClose} />
    </>
  );
}

export default Room;
